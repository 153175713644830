import React, { useState, useEffect } from 'react';
import { SellerNav } from './SellerNav';
import api from '../api';
import { useNavigate } from 'react-router-dom';
export const UnSoldCars = () => {
const navigate = useNavigate();
const [VehicleListCount, setVehicleCount] = useState(0); // State for vehicle count
const [posts, setPosts] = useState([]);
const [error, setError] = useState(null);
const storedUserId = sessionStorage.getItem('user_id');
const [isNewUser, setIsNewUser] = useState(false);
const [searchQuery, setSearchQuery] = useState('');
const [loading, setLoading] = useState(true); // Add a loading state

useEffect(() => {
sessionStorage.removeItem('isNewUser');
setIsNewUser(false);
const fetchPosts = async () => {
try {
if (!storedUserId) {
navigate('/sellerin');
return; // Stop further execution
}        
const response = await api.get(`/vehiclelisting/${storedUserId}/Seller/UnSoldCars`);
const { message, Vehicle } = response.data;
// Check if message is true and Vehicle is an array
if (message && Array.isArray(Vehicle)) {
// Log the entire response data
// Set the Vehicle data in state
setVehicleCount(Vehicle.length);  // Count the number of vehicles
setLoading(false); 
setPosts(Vehicle);
} else {
throw new Error('Invalid data format: Vehicle array not found');
setLoading(false); 
}
} catch (error) {
setError(error.message);
setLoading(false); 
}
};
fetchPosts();
}, [navigate, storedUserId]);
const fetchSerchdata = async () => {
try {
const response = await api.post(`/vehiclesearch/${storedUserId}/Seller`, {
search: searchQuery
});
const vehicles = response.data.Vehicle;
setPosts(vehicles);
} 
catch (error) {
console.error('Error fetching data:', error);
}
};
const handleSearchChange = (event) => {
setSearchQuery(event.target.value);
fetchSerchdata();
};
return (
<section className="car-details">
   <SellerNav />
   <div className="container">
      <div className="view-post-panel mid-table mt-4">
         <div class="toplisting">
         <h3 className="main-heading py-3">Unsold Cars</h3>
               <div class="badge badge-primary p-2">Total Count:   <div class="count"><strong>{VehicleListCount}</strong></div></div>
            </div>
         <div className="car-bid-gallary">
            <div className="carbid-gallery-panel py-4">
               <div className="row">
               {loading ? (
                  <p>Loading...</p> // Render loading state while fetching data
                  ) : posts.length === 0 ? (
                  <div className="col-md-12">
                     <p className="text-center text-danger">No Unsold Cars</p>
                  </div>
                  ) : (
               posts.map(post => (
                  <div key={post.id} className="col-lg-4 col-12">
                     <a href={`/unsold-car-details/${post.Vehicle_Id}`}>
                        <div className="carbid-image-panel">
                           {/* Replace 'your_static_id' with your desired static ID */}
                           {post.Exterior_Image && (
                           <img
                              src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image}`}
                              alt="Car"
                              />
                           )}
                           {post.Exterior_Image2 && !post.Exterior_Image && (
                           <img
                              src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image2}`}
                              alt="Car"
                              />
                           )}
                           {post.Exterior_Image3 && !post.Exterior_Image2 && !post.Exterior_Image && (
                           <img
                              src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image3}`}
                              alt="Car"
                              />
                           )}
                           {post.Exterior_Image4 && !post.Exterior_Image3 && !post.Exterior_Image2 && !post.Exterior_Image && (
                           <img
                              src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image4}`}
                              alt="Car"
                              />
                           )}
                           <div className="bidpanel-innercont">
                              <h6 className="pt-2 text-capitalize">{post.Year} {post.Make} {post.Model}</h6>
                              <span><small>Vin #{post.VIN}</small></span>
                           </div>
                        </div>
                     </a>
                  </div>
                  ))
               )}
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
);
};