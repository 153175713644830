import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SellerNav } from './SellerNav';
import api from '../api';
import { useNavigate } from 'react-router-dom';

export const DealerBidResponse = () => {
  const { Vehicle_Id } = useParams(); // Get the ID from the URL slug
  const [bidNegotiations, setBidNegotiations] = useState([]);
  const [message, setMessage] = useState(''); // State to store success/error message
  const navigate = useNavigate();

  useEffect(() => {
    BidFetchData();
  }, []);

  const BidFetchData = async () => {
    try {
      const response = await api.get(`/show-negotiation/${Vehicle_Id}`);
      setBidNegotiations(response.data.bidnegotation);
      console.log('Bid Negotiations Data:', response.data.bidnegotation);
    } catch (error) {
      console.error('Error fetching Bid details:', error);
    }
  };

  // Function to handle acceptance/decline of offers
  const handleOfferResponse = async (action, dealer) => {
    const { dealer_id, seller_id, dealer_counter_offer, dealer_comments } = dealer;

    const data = {
      BidID: dealer.bid_id, // Assuming each negotiation has a unique bid_id
      VehicleID: Vehicle_Id, // The vehicle ID from URL params
      DealerID: dealer_id,
      SellerID: seller_id,
      CounterPrice: dealer_counter_offer,
      Comments: dealer_comments,
      Action: action,
      PortalName: 'Seller' // Hardcoded as per your requirement
    };

    try {
      const response = await api.post('/negotiation', data);
      console.log('Response after action:', response.data); // Handle the response as needed

      // Show success message based on action
      if (action === 'Accept') {
        setMessage('Offer accepted successfully!');
      } else {
        setMessage('Offer declined successfully!');
      }
    } catch (error) {
      console.error('Error responding to the offer:', error);
      setMessage('There was an error processing your response. Please try again.');
    }
  };

  const handleNewCounterPrice = () => {
    let ids = [];
  
    bidNegotiations.forEach((negotiation, index) => {
      console.log('Negotiation object:', negotiation); // Check if Bid_Id and Dealer_Id exist here
      if (negotiation.bid_id && negotiation.dealer_id) {
        ids.push(negotiation.bid_id, negotiation.dealer_id);
      } else {
        console.warn(`Missing bid_id or dealer_id in negotiation at index ${index}`);
      }
    });
  
    // Ensure that Vehicle_Id is defined
    console.log('Vehicle_Id:', Vehicle_Id);
  
    // Join the IDs with '/' and navigate to the new page with these params
    if (ids.length > 0) {
      const url = `/bid/${Vehicle_Id}/${ids.join('/')}`;
      console.log('Navigating to:', url);
      navigate(url);
    } else {
      console.warn('No valid IDs found for navigation.');
    }
  
    // Check what IDs were collected before navigation
    console.log('Collected IDs:', ids);
  };

  return (
    <section className="car-details">
      <SellerNav />
      <div className="container">
        <div className="nago-mainwrapper dealer-nego">
                {/* Display the seller's negotiation if available */}
                {bidNegotiations.length > 0 && (
            <div>
              <h2>My Negotiation</h2>
              <p>Counter Price: {bidNegotiations[0]?.seller_counter_offer}</p>
              <p>Comments: {bidNegotiations[0]?.seller_comments}</p>
              <div className="text-center mt-3">
                <a className="btn btn-primary text-center" onClick={handleNewCounterPrice}>
                  New Counter Price
                </a>
              </div>
            </div>
          )}
          
          <h5 className="text-center main-heading">Dealer Response</h5>

          {/* Show message if available */}
          {message && (
            <div className="alert alert-info text-center">
              {message}
            </div>
          )}

          {/* Check the number of negotiations and render accordingly */}
          {bidNegotiations.length > 0 && (
            <>
              <h2>Dealer</h2>
              <h5>Counter Price:</h5>
              <p>${bidNegotiations[0].dealer_counter_offer}</p>
              <h5>Comments:</h5>
              <p>{bidNegotiations[0].dealer_comments}</p>
              <div className="text-center mt-4">
                <button 
                  className="btn btn-primary mr-3" 
                  onClick={() => handleOfferResponse('Accept', bidNegotiations[0])}
                >
                  Accept
                </button>
                <button 
                  className="btn btn-primary" 
                  onClick={() => handleOfferResponse('Decline', bidNegotiations[0])}
                >
                  Decline
                </button>
              </div>
              <hr />
            </>
          )}

          {bidNegotiations.length > 1 && (
            <>
              <h2>Dealer 2</h2>
              <h5>Counter Price:</h5>
              <p>${bidNegotiations[1].dealer_counter_offer}</p>
              <h5>Comments:</h5>
              <p>{bidNegotiations[1].dealer_comments}</p>
              <div className="text-center mt-4">
                <button 
                  className="btn btn-primary mr-3" 
                  onClick={() => handleOfferResponse('Accept', bidNegotiations[1])}
                >
                  Accept
                </button>
                <button 
                  className="btn btn-primary" 
                  onClick={() => handleOfferResponse('Decline', bidNegotiations[1])}
                >
                  Decline
                </button>
              </div>
              <hr />
            </>
          )}
        </div>
      </div>
    </section>
  );
};
